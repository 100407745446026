<template>
    <main-confirm @confirm="$emit('saveAddress')" @cancel="$emit('closeModal')" confirm-title="auth.confirm" :buttonConfirmTitle="confirmTitle" 
    :buttonCancelTitle="'common.edit'" description="account.is_this_your_correct_address">
        <icon-confirm-address :size="110"/>
        <template #map>
            <MglMap style="height: 270px; border-radius: 10px;" :center="coordinates" :accessToken="accessToken" class="my-4 overflow-hidden"
                :zoom="8" :dragRotate="false" mapStyle="mapbox://styles/fbernabe/clasqq2sj002w14qp6xg4dkus" ref="confirmMap">      
                <MglMarker style="cursor: pointer" :coordinates="coordinates" :draggable="false" color="#c40316">
                </MglMarker>
            </MglMap>
        </template>
    </main-confirm>
</template>

<script>
import IconConfirmAddress from '../../../assets/svg/iconConfirmAddress.vue';
import MainConfirm from '../../../../../common/components/modals/MainConfirm.vue';
import { MglMap, MglMarker } from 'v-mapbox'

export default {
    name: "ConfirmLocation",
    data(){
        return {
            accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
        }
    },  
    components: {
        MainConfirm,
        IconConfirmAddress,
        MglMap, MglMarker
    },
    props:{
        confirmTitle:{
            type: String
        },
        coordinates:{
            type: Array
        }
    },
    mounted(){
        const animationOptions = {
            animate: true, duration: 4000, essential: true
        }
        setTimeout(() => {
            this.$refs.confirmMap.actions.zoomTo(18, animationOptions)
        }, 1000);
    }
}
</script>

<style scoped>
    .main-confirm::v-deep .modal-main{
        min-width: 700px;
    }
</style>7