import { render, staticRenderFns } from "./iconBranch.vue?vue&type=template&id=0b5f24b6&scoped=true"
import script from "./iconBranch.vue?vue&type=script&lang=js"
export * from "./iconBranch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b5f24b6",
  null
  
)

export default component.exports