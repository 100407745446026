<template>
    <main-modal @closeModal="$emit('closeModal')" :class="'create-address'" :overflowHidden="overflowHidden" :preventClose="true">
        <div class="d-flex align-items-center position-relative" style="height: 20px;">
            <transition name="star">
                <icon-star :size="20" class="me-3" v-if="form.default"/>
            </transition>
            <span :class="[form.default ? 'title-right':'title-left']" class="title title-20 mb-0 position-absolute">{{addressEditData ? $t('account.modify_my_address') : $t('account.add_address')}}</span>
        </div>
        <div class="row mt-3 mb-2" v-if="configurations.general.web_page.address_format != 'us'">
            <div class="col-md-6">
                <span class="d-flex align-items-center subtitle-16">
                    {{$t("account.drag_the")}}
                    <icon-pin color="#CF2127" class="me-2 ms-2"/>
                    <span class="d-none d-md-inline">
                        {{$t("account.to_your_exact_location")}}
                    </span>
                </span>
            </div>
            <div class="col-md-6">
                <ul class="nav" style="justify-content: end;">
                    <li class="nav-item subtitle-14" :key="index" v-for="(style, index) in mapStyles">
                        <div class="d-flex align-items-center">
                            <a class="nav-link p-2" @click.prevent="styleSelected = index" :class="{ 'active-style': index != styleSelected }" href="#">
                                {{$t('account.'+ index)}}
                            </a>
                            <span v-if="index === 'Mapa'" class="color-dark-gray-200">|</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div style="border-radius: 7px;" v-if="configurations.general.web_page.address_format != 'us'">
            <MglMap @click="clickMap" style="height: 270px;" :center="form.center" :accessToken="accessToken" :zoom="7" :dragRotate="false" @load="onMapLoaded" ref="map" :mapStyle="mapStyle" >      
                <vue-google-autocomplete id="vue-google-autocomplete" @focus="showMessage = true" v-model="verifyFormat" :placeholder="$t('account.write_a_reference_to_locate')" 
                class="form-control autocomplete" :country="[getRegions]" :types="['locality']" v-on:placechanged="setPlace" @change="autoCompleteChange" :class="{ 'is-invalid': verifyFormatValidate }" />
                <div class="invalid-feedback d-flex gmap-invalid" v-if="verifyFormatValidate && !customer">
                    <div>
                        {{ $t("common.required_field") }} {{$t("account.to_be_sure_of_your_location")}}
                    </div>
                </div>
                <MglMarker style="cursor: pointer" :coordinates="form.coordinates" :draggable="true" @dragend="dragUbication" color="#c40316">
                    <MglPopup :showed="isShow" closeOnClick :coordinates="form.coordinates" anchor="top">
                        <div><icon-warning :size="14" class="me-2 hithere" color="var(--blue-600)" /> {{$t("account.drag_the")}} <icon-pin color="#CF2127" class="me-2 ms-2"/> {{$t("account.to_your_exact_location")}}</div>
                    </MglPopup>
                </MglMarker>
                <MglGeolocateControl :trackUserLocation="true" position="top-right" />
                <MglNavigationControl position="top-right"/>
            </MglMap>
        </div>
        <div class="row" v-if="configurations.general.web_page.address_format != 'us'">
            <div class="col-lg-9 col-md-8 mt-3">
                <h6 class="subtitle-16 me-3">{{$t("account.fill_in_the_following")}}</h6>
            </div>
            <div class="col-lg-3 col-md-4 d-flex mt-3" v-if="!customer">
                <main-switch :id="'default-address'" description="account.set_as_default" :size="10" :value="form.default" @changeValue="(data) => {form.default = data}"/>
            </div>
            <main-input id="name-person" class="col-md-4 mt-3" ref="name" title="account.name_person" :value="form.name" @changeValue="(data) => {form.name = data;}" :readonly="readonly" :placeholder="'account.name_person'" @activeInput="readonly = false">
                <icon-person :size="15" :color="'var(--dark-gray-200)'"/>
            </main-input>
            <div class="col-md-4 mt-3">
                <span class="title-14 mb-0" >{{ $t('auth.phone_number') | textFormat }}</span>
                <VuePhoneNumberInput class="vue-phone-number-input" style='position: relative; top: 6px;' :valid-color="color" :translations="translations" :default-country-code="countryCode" :border-radius="7" :error="isValidPhone == false" ref="phone" id="phone" v-model="form.phone" @update="handlePhoneNumberInput" />
            </div>
            <!-- <main-input id="phone-number" class="col-md-4 mt-3" ref="phone" type="phone" title="account.phone_number" :value="form.phone" @changeValue="(data) => {form.phone = data;}" :readonly="readonly" :mask="'+1 ###-###-####'" :placeholder="'+1 (809)-000-0000'"
            @activeInput="readonly = false">
                <icon-phone :size="15" :color="'var(--dark-gray-200)'"/>
            </main-input> -->
            <div class="col-md-4 mt-3">
                <div class="d-flex mb-2">
                    <span class="subtitle-15 weight-600">{{$t("account.city")}} <span class="color-main-red" v-if="$v.form.city.$error">*</span></span>
                </div>
                <div class="position-relative">
                    <multi-select ref="city" v-model="form.city" :searchable="true" :tagPlaceholder="$t('account.please_select_an_option')" :placeholder="$t('account.please_select_an_option')"
                        :deselect-labe="$t('DESELECT')" label="city" :options="cities" :class="{'multi-is-invalid': $v.form.city.$error}">
                        <icon-branch :size="15" class="me-2" :color="'var(--dark-gray-200)'"/>
                        <div>{{$t('account.please_select_an_option')}}</div>
                        <span slot="noOptions">
                            {{ $t("account.the_list_is_empty") }}
                        </span>
                        <span slot="noResult">
                            {{ $t("account.no_result_found") }}
                        </span>
                    </multi-select>
                    <div v-if="$v.form.city.$error && cityError" class="invalid-input">
                        <icon-warning class="me-2" red :size="14"/>
                        <span class="color-main-red size-14">{{ $t("common.required_field") }}.</span>
                    </div>
                </div>
            </div>
            <main-input ref="textualAddress" class="col-md-6 mt-3" id="textual_direction" title="account.textual_direction" :value="form.address_line2" :readonly="readonly" @changeValue="(data)=>{form.address_line2 = data;}" placeholder="account.description_address"
            @activeInput="readonly = false">
                <icon-addresses :size="15" :color="'var(--dark-gray-200)'"/>
            </main-input>
            <main-input ref="reference" class="col-md-6 mt-3" id="reference" title="account.reference" :value="form.reference" :readonly="readonly" @changeValue="(data)=>{form.reference = data;}" placeholder="account.next_to_the_mobile_world"
            @activeInput="readonly = false">
                <icon-reference :size="15" :color="'var(--dark-gray-200)'"/>
            </main-input>    
        </div>
        <template v-if="configurations.general.web_page.address_format == 'us'">
                <div class="row">
                    <div class="col-lg-9 col-md-8 mt-3">
                        <h6 class="subtitle-16 me-3">{{$t("account.fill_in_the_following")}}</h6>
                    </div>
                    <div class="col-lg-3 col-md-4 d-flex mt-3" v-if="!customer">
                        <main-switch :id="'default-address'" description="account.set_as_default" :size="10" :value="form.default" @changeValue="(data) => {form.default = data}"/>
                    </div>
                </div>
                <div class="row">
                    <main-input id="name-person" class="col-md-11 mt-3" ref="name" title="account.name_person" :value="form.name" @changeValue="(data) => {form.name = data;}" :readonly="readonly" :placeholder="'account.name_person'" @activeInput="readonly = false">
                        <icon-person :size="15" :color="'var(--dark-gray-200)'"/>
                    </main-input>
                    <!-- <main-input id="phone-number" class="col-md-11 mt-3" ref="phone" type="phone" title="account.phone_number" :value="form.phone" @changeValue="(data) => {form.phone = data;}" :readonly="readonly" :mask="'+1 ###-###-####'" :placeholder=" configurations.general.web_page.address_format == 'us' ? '+1 (000)-000-0000'  :  '+1 (809)-000-0000'"
                    @activeInput="readonly = false">
                        <icon-phone :size="15" :color="'var(--dark-gray-200)'"/>
                    </main-input> -->
                    <div class="col-md-11 mt-3">
                        <span class="title-14 mb-0" >{{ $t('auth.phone_number') | textFormat }}</span>
                        <VuePhoneNumberInput class="vue-phone-number-input" style='position: relative; top: 6px;' :valid-color="color" :translations="translations" :default-country-code="countryCode" :border-radius="7" :error="isValidPhone == false" ref="phone" id="phone" v-model="form.phone" @update="handlePhoneNumberInput" />
                    </div>
                    <main-input v-if="configurations.general.web_page.address_format == 'us'" ref="textualAddress2" class="col-md-11 mt-3" id="textual_direction" title="Address" :value="form.address_line" :readonly="readonly" @changeValue="(data)=>{form.address_line = data;}" placeholder="house number, street"
                    @activeInput="readonly = false">
                        <icon-addresses :size="15" :color="'var(--dark-gray-200)'"/>
                    </main-input>
                    <main-input ref="textualAddress"  class="col-md-11 mt-1" id="textual_direction" :title="''" :value="form.address_line2" :readonly="readonly" @changeValue="(data)=>{form.address_line2 = data;}" placeholder="apartment, unit, building, floor, etc."
                    @activeInput="readonly = false">
                        <icon-addresses :size="15" :color="'var(--dark-gray-200)'"/>
                    </main-input>
                    <main-input ref="reference" class="col-md-11 mt-3" id="reference" title="account.reference" :value="form.reference" :readonly="readonly" @changeValue="(data)=>{form.reference = data;}" placeholder="account.next_to_the_mobile_world"
                    @activeInput="readonly = false">
                        <icon-reference :size="15" :color="'var(--dark-gray-200)'"/>
                    </main-input>
                </div>
                <div class="row" >
                    <div class="col-md-4 mt-3">
                        <div class="d-flex mb-2">
                            <span class="subtitle-15 weight-600">{{$t("account.city")}} <span class="color-main-red" v-if="$v.form.city.$error">*</span></span>
                        </div>
                        <div class="position-relative">
                            <multi-select ref="city" v-model="form.city" :searchable="true" :tagPlaceholder="$t('account.please_select_an_option')" :placeholder="$t('account.please_select_an_option')"
                                :deselect-labe="$t('DESELECT')" label="city" :options="cities" :class="{'multi-is-invalid': $v.form.city.$error}">
                                <icon-branch :size="15" class="me-2" :color="'var(--dark-gray-200)'"/>
                                <div>{{$t('account.please_select_an_option')}}</div>
                                <span slot="noOptions">
                                    {{ $t("account.the_list_is_empty") }}
                                </span>
                                <span slot="noResult">
                                    {{ $t("account.no_result_found") }}
                                </span>
                            </multi-select>
                            <div v-if="$v.form.city.$error && cityError" class="invalid-input">
                                <icon-warning class="me-2" red :size="14"/>
                                <span class="color-main-red size-14">{{ $t("common.required_field") }}.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3" v-if="configurations.general.web_page.address_format == 'us'">
                        <div class="d-flex mb-2">
                            <span class="subtitle-15 weight-600">{{$t("State")}} <span class="color-main-red" v-if="$v.form.state.$error">*</span></span>
                        </div>
                        <div class="position-relative">
                            <multi-select ref="state" v-model="form.state" :searchable="true" :tagPlaceholder="$t('account.please_select_an_option')" :placeholder="$t('account.please_select_an_option')"
                                :deselect-labe="$t('DESELECT')" label="name" :options="states" :class="{'multi-is-invalid': $v.form.state.$error}">
                                <icon-branch :size="15" class="me-2" :color="'var(--dark-gray-200)'"/>
                                <div>{{$t('account.please_select_an_option')}}</div>
                                <span slot="noOptions">
                                    {{ $t("account.the_list_is_empty") }}
                                </span>
                                <span slot="noResult">
                                    {{ $t("account.no_result_found") }}
                                </span>
                            </multi-select>
                            <div v-if="$v.form.state.$error && stateError" class="invalid-input">
                                <icon-warning class="me-2" red :size="14"/>
                                <span class="color-main-red size-14">{{ $t("common.required_field") }}.</span>
                            </div>
                        </div>
                    </div>
                    <main-input v-if="configurations.general.web_page.address_format == 'us'" id="zip-code" class="col-md-3 mt-3" ref="postal_code" title="Postal code" :value="form.postal_code" @changeValue="(data) => {form.postal_code = data;}" :readonly="readonly" :placeholder="'Code'" @activeInput="readonly = false">
                        <icon-addresses :size="15" :color="'var(--dark-gray-200)'"/>
                    </main-input>
                </div>
        </template>
        <div class="d-flex mt-3" :class="configurations.general.web_page.address_format == 'us' ? 'justify-content-end align-items-end' : 'justify-content-between align-items-center'">
            <div class="d-flex align-items-center" v-if="configurations.general.web_page.address_format != 'us'">
                <div class="legend-map"></div>
                <span class="subtitle-14 ms-2">{{$t("account.area_available_for_delivery")}}</span>
            </div>
            <div v-if="store" class="d-flex justify-content-end">
                <main-button title="account.save" icon :disabled="loadingAddress" v-if="addressEditData ===  null" @click="confirmCoordenates">
                    <half-circle-spinner :animation-duration="1000" :size="15" color="#fff" v-if="loadingAddress" class="me-1" style="position: relative; right: 7px;"/>
                </main-button>
            </div>
            <div v-else-if="!customer" class="d-flex justify-content-end">
                <main-button title="account.save" icon v-if="addressEditData ===  null" @click="confirmLocation" :disabled="loadingAddress">
                    <half-circle-spinner :animation-duration="1000" :size="15" color="#fff" v-if="loadingAddress" class="me-1" style="position: relative; right: 7px;"/>
                </main-button>
                <main-button title="account.save" v-else @click="validationEmploye"/>
            </div>
        </div>
        <confirm-location @closeModal="closeModalMap" @saveAddress="saveAddress"  v-if="showConfirmLocation" 
        :confirmTitle="loading ? 'account.saving' : 'auth.confirm'" :coordinates="form.coordinates"/>
        <direction-not-allowed v-if="directionNotAllowed" @closeModal="directionNotAllowed = false"/>
    </main-modal>
</template>

<script>

import 'mapbox-gl/dist/mapbox-gl.css';
import 'v-mapbox/dist/v-mapbox.css';
import MainInput from '../../../../../common/components/inputs/MainInput.vue'
import MainModal from '../../../../../common/components/modals/MainModal.vue'
import MainSwitch from '../../../../../common/components/switch/MainSwitch.vue'
import IconBranch from '../../../../../common/svg/iconBranch.vue'
// import IconPhone from '../../../../../common/svg/iconPhone.vue'
import IconReference from '../../../../../common/svg/iconReference.vue'
import IconStar from '../../../../../common/svg/iconStar.vue'
import IconAddresses from '../../../../layout/assets/svg/iconAddresses.vue'
import IconPerson from '@/common/svg/iconPerson.vue'
import IconPin from '../../../../../common/svg/iconPin.vue'
import IconWarning from '../../../../../common/svg/iconWarning.vue'
import { PhoneNumberUtil } from 'google-libphonenumber';
import { MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup } from 'v-mapbox'
import Mapbox from "mapbox-gl";
import { http } from "@/common/services/web-backend.js";
import MultiSelect from 'vue-multiselect'
import MainButton from '../../../../../common/components/buttons/MainButton.vue'
import ConfirmLocation from './ConfirmLocation.vue'
import { addressesMixin } from '../../../helpers/mixins/addresses-mixin'
import DirectionNotAllowed from './DirectionNotAllowed.vue'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from "vuex"
import { HalfCircleSpinner } from "epic-spinners";
import axios from 'axios'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import iconWarning from "../../../../../common/svg/iconWarning.vue";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
    name: "CreateAddress",
    components: {
        MainModal, MainSwitch, MainInput, MainButton, MultiSelect,
        IconStar, IconPerson, IconBranch, IconAddresses, IconReference, IconPin, IconWarning,//icons
        MglMap, MglMarker, MglGeolocateControl, MglNavigationControl, MglPopup, //maps
        ConfirmLocation,
        DirectionNotAllowed,
        VueGoogleAutocomplete,
        iconWarning,
        VuePhoneNumberInput,
        HalfCircleSpinner
    },
    props: {
        addressEditData: {
            type: Object,
            default: null
        },
        store: {
            type: Boolean
        },
        overflowHidden:{
            type: Boolean,
            default: true
        },
        customer:{
            type: Boolean,
            default: false
        },
        add:{
            type: Boolean
        },
        reset:{
            type: Boolean
        }
    },
    mixins: [addressesMixin],
    data(){
        return {
            value: null,
            region: '',
            //style for map
            mapStyles: {
                Mapa: "mapbox://styles/fbernabe/clasqq2sj002w14qp6xg4dkus",
                Satellite: "mapbox://styles/fbernabe/clasrg86g000314o5cropapiq",
            },
            styleSelected: "Mapa",
            accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
            form : {
                name: "",
                default: false,
                phone: "",
                address_line: "",
                address_line2: "",
                reference: "",
                city: '',
                postal_code: '',
                address: "",
                state: '',
                coordinates: [-70.70238447994983, 19.45556310283655],
                center: [-70.70238447994983, 19.45556310283655]
            },
            id: null,
            layers: [],
            cities: [],
            states: [
                        {
                            name: "Alabama",
                            id: "AL"
                        },
                        {
                            name: "Alaska",
                            id: "AK"
                        },
                        {
                            name: "American Samoa",
                            id: "AS"
                        },
                        {
                            name: "Arizona",
                            id: "AZ"
                        },
                        {
                            name: "Arkansas",
                            id: "AR"
                        },
                        {
                            name: "California",
                            id: "CA"
                        },
                        {
                            name: "Colorado",
                            id: "CO"
                        },
                        {
                            name: "Connecticut",
                            id: "CT"
                        },
                        {
                            name: "Delaware",
                            id: "DE"
                        },
                        {
                            name: "District Of Columbia",
                            id: "DC"
                        },
                        {
                            name: "Federated States Of Micronesia",
                            id: "FM"
                        },
                        {
                            name: "Florida",
                            id: "FL"
                        },
                        {
                            name: "Georgia",
                            id: "GA"
                        },
                        {
                            name: "Guam",
                            id: "GU"
                        },
                        {
                            name: "Hawaii",
                            id: "HI"
                        },
                        {
                            name: "Idaho",
                            id: "ID"
                        },
                        {
                            name: "Illinois",
                            id: "IL"
                        },
                        {
                            name: "Indiana",
                            id: "IN"
                        },
                        {
                            name: "Iowa",
                            id: "IA"
                        },
                        {
                            name: "Kansas",
                            id: "KS"
                        },
                        {
                            name: "Kentucky",
                            id: "KY"
                        },
                        {
                            name: "Louisiana",
                            id: "LA"
                        },
                        {
                            name: "Maine",
                            id: "ME"
                        },
                        {
                            name: "Marshall Islands",
                            id: "MH"
                        },
                        {
                            name: "Maryland",
                            id: "MD"
                        },
                        {
                            name: "Massachusetts",
                            id: "MA"
                        },
                        {
                            name: "Michigan",
                            id: "MI"
                        },
                        {
                            name: "Minnesota",
                            id: "MN"
                        },
                        {
                            name: "Mississippi",
                            id: "MS"
                        },
                        {
                            name: "Missouri",
                            id: "MO"
                        },
                        {
                            name: "Montana",
                            id: "MT"
                        },
                        {
                            name: "Nebraska",
                            id: "NE"
                        },
                        {
                            name: "Nevada",
                            id: "NV"
                        },
                        {
                            name: "New Hampshire",
                            id: "NH"
                        },
                        {
                            name: "New Jersey",
                            id: "NJ"
                        },
                        {
                            name: "New Mexico",
                            id: "NM"
                        },
                        {
                            name: "New York",
                            id: "NY"
                        },
                        {
                            name: "North Carolina",
                            id: "NC"
                        },
                        {
                            name: "North Dakota",
                            id: "ND"
                        },
                        {
                            name: "Northern Mariana Islands",
                            id: "MP"
                        },
                        {
                            name: "Ohio",
                            id: "OH"
                        },
                        {
                            name: "Oklahoma",
                            id: "OK"
                        },
                        {
                            name: "Oregon",
                            id: "OR"
                        },
                        {
                            name: "Palau",
                            id: "PW"
                        },
                        {
                            name: "Pennsylvania",
                            id: "PA"
                        },
                        {
                            name: "Puerto Rico",
                            id: "PR"
                        },
                        {
                            name: "Rhode Island",
                            id: "RI"
                        },
                        {
                            name: "South Carolina",
                            id: "SC"
                        },
                        {
                            name: "South Dakota",
                            id: "SD"
                        },
                        {
                            name: "Tennessee",
                            id: "TN"
                        },
                        {
                            name: "Texas",
                            id: "TX"
                        },
                        {
                            name: "Utah",
                            id: "UT"
                        },
                        {
                            name: "Vermont",
                            id: "VT"
                        },
                        {
                            name: "Virgin Islands",
                            id: "VI"
                        },
                        {
                            name: "Virginia",
                            id: "VA"
                        },
                        {
                            name: "Washington",
                            id: "WA"
                        },
                        {
                            name: "West Virginia",
                            id: "WV"
                        },
                        {
                            name: "Wisconsin",
                            id: "WI"
                        },
                        {
                            name: "Wyoming",
                            id: "WY"
                        }
                    ],
            showConfirmLocation: false,
            directionNotAllowed: false,
            loading: false,
            latitud: null,
            longitud: null,
            readonly: true,
            stateError: false,
            cityError: false,
            loadingAddress: false,
            isShow: false,
            confirmValidate: false,
            isValidPhone: null,
            countryCode: this.verifyUSTECH() ? 'US' : 'DO',
            color: 'yellowgreen',
            translations:{
                countrySelectorLabel: this.$t('auth.country_code'),
                countrySelectorError: this.$t('auth.selected_country'),
                phoneNumberLabel: this.$t('auth.phone_number'),
                example: this.$t('auth.example')
            },
        }
    },
    computed: {
        mapStyle(){
            return this.mapStyles[this.styleSelected];
        },
        sources() {
            let sources = [];
            for (let layer of this.layers){
                sources.push({
                'type': 'geojson',
                'data': layer
                })
            }
            return sources;
        },
        verifyFormat(){
            return this.configurations.general.web_page.address_format == 'us' ? this.form.address : this.form.address_line
        },
        verifyFormatValidate(){
            return this.configurations.general.web_page.address_format == 'us' ? this.$v.form.address.$error  : this.$v.form.address_line.$error
        },
        ...mapGetters({
            branchSelected: "branches/branchSelected",
            configurations: "common/configurations",
            employeeActiveCode: 'auth/employeeActiveCode',
            user:"auth/userData",
            employee: 'auth/employee',
        }),
        getRegions(){
            return this.configurations?.general?.web_page?.country_default?.text;
        }
    },
    validations: {
        form: {
            address_line: {
                required
            },
            city: {
                required
            },
            state: {
                required
            },
            address: {
                required
            }
        }
    },
    methods: {
        autoCompleteChange(data){
            if(this.configurations.general.web_page.address_format == 'us'){
                this.form.address = data
            }else{
                this.form.address_line = data;
            }
        },
        closeModalMap(){
            if(this.showConfirmLocation){
                this.showConfirmLocation = false
                this.isShow = true
            }
        },
        async clickMap(data){
            this.form.coordinates = [data.mapboxEvent.lngLat.lng, data.mapboxEvent.lngLat.lat];
            await this.getLocation(data.mapboxEvent.lngLat.lat, data.mapboxEvent.lngLat.lng)
        },
        async onMapLoaded(event){
            await this.getLimits(this.branchSelected)
            this.mapbox = Mapbox;
            this.sources.forEach((source, id) => {
                event.map.addSource('limit-areas-'+id, source);
                event.map.addLayer(
                    {
                        id: 'limit-areas-fill-'+id, type: 'fill', source: 'limit-areas-'+id, layout: {}, 
                        paint: { 'fill-color': '#5A8CDB', 'fill-opacity': 0.5}
                    },
                );
            })
            event.map.on('style.load', async () => {
                this.sources.forEach((source, id) => {
                event.map.addSource('limit-areas-'+id, source);
                event.map.addLayer(
                        {
                            id: 'limit-areas-fill-'+id, type: 'fill', source: 'limit-areas-'+id, layout: {},
                            paint: { 'fill-color': '#c40316', 'fill-opacity': 0.5 }
                        },
                    );
                })
            });
            const animationOptions = {
                animate: true, duration: 4000, essential: true
            }
            setTimeout(() => {
                event.map.zoomTo(14, animationOptions)
            }, 2000);
        },
        async setPlace(place) {
            this.form.coordinates = [place.longitude, place.latitude];
            this.form.center = [place.longitude, place.latitude];
        },
        async dragUbication(data){
            this.form.coordinates = [data.marker._lngLat.lng, data.marker._lngLat.lat];
            await this.getLocation(data.marker._lngLat.lat, data.marker._lngLat.lng)
        },
        async getCities(){
            const cities = await http.get('cities')
            this.cities = cities.data.data
        },
        validationEmploye(){
            if(this.employee?.entity?.activated_employee && !this.configurations.general.web_page.active_address_employee){
                this.$store.commit('common/SHOW_MODAL_ERROR', {
                    show: true, 
                    title: `account.no_access`,
                    description: `account.no_permit_is_available`
                })
                return;
            }
            if(this.configurations.general.web_page.address_format != 'us'){
                this.saveAddress()
            } else{
                this.confirmCoordenates();
            }
        },
        async getCoordinates(address, city) {
            try {
                // const baseUrl = 'https://nominatim.openstreetmap.org/search';
                // const url = `${baseUrl}?format=json&q=${encodeURIComponent(address)}, ${encodeURIComponent(city)}&addressdetails=1&limit=1`;
                
                // const response = await axios.get(url);
                this.loadingAddress = true
                const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                    params: {
                        // q: address,
                        format: 'json',
                        limit: 1,
                        city: city,
                        country: 'United states',
                        postalcode: this.form.postal_code,
                        state: this.form.state.name,
                        street: address,
                    }
                });

                if (response.status !== 200) {
                    this.loadingAddress = false
                    const error = {
                        show: true,
                        title: `Sorry, an error has occurred.`,
                        description: 'Error obtaining location data'
                    }
                    throw this.$store.commit('common/SHOW_MODAL_ERROR', error)
                }

                const data = response.data;
                if (data.length === 0) {
                    this.loadingAddress = false
                    const error = {
                        show: true,
                        title: `Sorry, an error has occurred.`,
                        description: 'No results were found for the address provided'
                    }
                    throw this.$store.commit('common/SHOW_MODAL_ERROR', error)
                }

                const { lat, lon } = data[0];
                this.form.coordinates[0] = lon
                this.form.coordinates[1] = lat
                if(!this.customer){
                    this.saveAddress()
                } else if(this.store){
                    this.saveAddressStore()
                }
                return { latitude: lat, longitude: lon };
            } catch (error) {
                console.error('Error:', error.message);
                this.loadingAddress = false
                return null;
            }
        },
        confirmCoordenates(){
            try {
                if(this.configurations.general.web_page.address_format == 'us'){
                    this.confirmValidate = true

                    const input =  this.form.city.city;
                    const separator = '/';

                    const lastIndex = input.lastIndexOf(separator);
                    
                    if (lastIndex !== -1) {
                        const result = input.slice(lastIndex + 1);
                        this.getCoordinates(this.form.address_line, result)
                    } else {
                        console.log('El separador no fue encontrado en la cadena.');
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        async saveAddress(){
            let address = {
                id: this.id,
                name: this.form.name,
                address_line: this.form.address_line,
                address_line2: this.form.address_line2,
                reference: this.form.reference,
                city: this.form.city,
                longitud: this.form.coordinates[0],
                latitud: this.form.coordinates[1],
                default: this.form.default,
                state: this.form.state?.id,
                country_code: this.configurations.general.web_page.address_format,
                postal_code: this.form.postal_code,
                phone: this.form.phone.replace(/[(||)| |+|-]/g, ""),
                validate: this.confirmValidate
            }

            if(this.addressEditData){
                if(this.addressEditData.name != address.name || this.addressEditData.address_line != address.address_line || this.addressEditData.address_line2 != address.address_line2
                    || this.addressEditData.reference != address.reference || this.addressEditData.city != address.city || this.addressEditData.postal_code != address.postal_code || this.addressEditData.state != address.state || this.addressEditData.default != address.default
                    || this.addressEditData.phone.replace(/[(||)| |+|-]/g, "") != address.phone || this.addressEditData.long != address.coordinates[0]
                    || this.addressEditData.lat != address.coordinates[1]
                ){
                    this.updateAddress(address)
                }else{
                    this.$emit('closeModal')
                }
            }
            else {
                try {
                    this.loading = true;
                    await this.addAddress(address)
                } catch (e) {
                    this.loadingAddress = false
                    const error = {
                        show: true,
                        title: `Lo sentimos ha ocurrido un error.`,
                        description: e.response.data.message
                    }
                    this.$store.commit('common/SHOW_MODAL_ERROR', error)
                }finally{
                    this.loadingAddress = false
                    this.loading = false
                }
            }
        },
        handlePhoneNumberInput(value){
            this.isValidPhone = value.isValid
            this.detectCountry(value.nationalNumber, value.countryCode)
        },
        detectCountry(phone, country) {
            this.color = 'yellowgreen'
            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const number = phoneUtil.parse(phone, country);
                const code = phoneUtil.getRegionCodeForNumber(number);
                if(code != null){
                    this.countryCode = code
                } else {
                    this.color = 'orangered'
                    this.isValidPhone = false
                }
            } catch (error) {
                console.log('Error al determinar el país del número de teléfono:', error);
            }
        },
        saveAddressStore(){
            try {
                const address = {
                    id: this.id,
                    name: this.form.name,
                    address_line: this.form.address_line,
                    address_line2: this.form.address_line2,
                    state: this.form.state,
                    postal_code: this.form.postal_code,
                    reference: this.form.reference,
                    city: this.form.city,
                    coordinates: this.form.coordinates,
                    default: this.form.default,
                    country_code: this.configurations.general.web_page.address_format,
                    phone: this.form.phone.replace(/[(||)| |+|-]/g, "")
                }
                
                this.$emit("address", address)
                
            } catch (error) {
                console.log(error);
            }
        },
        setDefault(){
            this.form.default = !this.form.default
        },
        confirmLocation(){
            this.$refs.name.$v.$touch();
            // this.$refs.phone.$v.$touch();
            if(this.configurations.general.web_page.address_format != 'us'){
                this.$refs.textualAddress.$v.$touch();
                this.$refs.reference.$v.$touch();
            }
            this.$v.form.city.$touch()
            if(this.configurations.general.web_page.address_format == 'us'){
                this.$v.form.state.$touch();
                this.$refs.postal_code.$v.$touch();
                this.$refs.textualAddress2.$v.$touch();
            }
            // this.$v.$touch();
            if(!this.customer){
                if(this.configurations.general.web_page.address_format != 'us'){
                    this.$v.form.address_line.$touch()
                }
            }

            if(this.configurations.general.web_page.address_format == 'us'){
                if(this.$refs.name.$v.$invalid || this.$v.form.address_line.$error || this.$refs.postal_code.$invalid 
                    || this.$v.form.state.$invalid || this.$refs.textualAddress2.$v.$invalid){
                        return false 
                }
            }else{
                if(this.$refs.name.$v.$invalid || this.$refs.textualAddress.$v.$invalid || this.$refs.reference.$v.$invalid 
                    || this.$v.form.address_line.$error){
                    return false
                }
            }
            if(!this.isValidPhone){
                this.isValidPhone = false
                return false
            }
            if(this.configurations.general.web_page.address_format == 'us'){
                this.confirmCoordenates()
                return true
            }
            if(!this.customer)
                this.showConfirmLocation = true;
            return true
        },
        addAddressCustomer(){
            if(this.configurations.general.web_page.address_format == 'us'){
                this.confirmValidate = true
            }
            const address = {
                id: this.id,
                name: this.form.name,
                address_line: this.form.address_line,
                address_line2: this.form.address_line2,
                reference: this.form.reference,
                city: this.form.city,
                longitud: this.form.coordinates[0],
                state: this.form.state,
                // city_name: this.form.city_name,
                postal_code: this.form.postal_code,
                latitud: this.form.coordinates[1],
                default: this.form.default,
                phone: this.form.phone.replace(/[(||)| |+|-]/g, ""),
                validate:  this.confirmValidate
            }
            const validate = this.confirmLocation()
            this.$emit('addCustomerAddress', {address, validate})
        },
        async getLocation(latitude, longitude){
            try {
                const address = await axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat='+latitude+'&lon='+longitude)
                if(this.configurations.general.web_page.address_format == 'us'){
                    this.form.address = address.data.display_name;
                }else{
                    this.form.address_line = address.data.display_name;
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted(){
        document.querySelector('.vue-phone-number-input input').style.minHeight = '36px';
        document.querySelector('.vue-phone-number-input input').style.height = '39px';

        document.querySelector('.vue-phone-number-input .input-tel__input').style.minHeight = '36px';
        document.querySelector('.vue-phone-number-input .input-tel__input').style.height = '39px';

        document.querySelector('.multiselect .multiselect__tags').style.minHeight = '38px';
    },
    async created(){
        // *<------------- REVIEW 27/02/2023 --------------->
            // const country = this.configurations.general.web_page.country_default.text
            // this.region = country
        // *<------------- REVIEW 27/02/2023 --------------->
        if(this.addressEditData){
            this.id = this.addressEditData.id;
            this.form.address_line = this.addressEditData.address_line;
            this.form.address_line2 = this.addressEditData.address_line2;
            this.form.name = this.addressEditData.name
            this.form.city = this.addressEditData.city
            this.form.default = this.addressEditData.default
            this.form.state = this.states.filter((data) => data.id === this.addressEditData.state)[0],
            // this.form.city_name = this.addressEditData.city_name,
            this.form.postal_code = this.addressEditData.postcode,
            this.form.reference = this.addressEditData.reference;
            this.form.phone = this.addressEditData.phone

            this.form.center =  [this.addressEditData.longitud, this.addressEditData.latitud]
            this.form.coordinates = [this.addressEditData.longitud, this.addressEditData.latitud]
        }else {
            this.form.center = [this.branchSelected.long, this.branchSelected.lat ]
            this.form.coordinates = [this.branchSelected.long, this.branchSelected.lat ]
        }
        if(this.customer){
            const {lat, long} = this.$route.query
            this.form.coordinates = [long, lat]
            this.form.center = [long, lat]
        }
        await this.getCities()
    },
    watch:{
        add(){
            this.addAddressCustomer()
        },
        reset(){
            this.form = {
                name: "",
                default: false,
                phone: "",
                address_line: "",
                address_line2: "",
                reference: "",
                state: '',
                postal_code: '',
                city: '',
                center: this.form.center,
            }
            setTimeout(() => {
                this.$refs.name.$v.$reset();
                this.$refs.phone.$v.$reset();
                this.$refs.state.$v.$reset();
                this.$refs.postal_code.$v.$reset();
                this.$refs.textualAddress.$v.$reset();
                this.$refs.reference.$v.$reset();
                this.$v.form.address_line.$reset()
                this.$v.form.city.$reset()
            }, 100);
        }
    }
}
</script>

<style scoped>
    @import url('../../../../../common/assets/styles/multiselect/vue-multiselect.min.css');

    @keyframes animation-invalid-input {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }to {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    .mgl-map-wrapper{
        border-radius: 7px;
        overflow: hidden;
    }
    .autocomplete{
        position: absolute;
        width: 310px;
        left: 20px;
        top: 20px;
        border: 0;
    }
    @media (max-width: 576px) {
        .autocomplete{
            left: 10px;
        }
        .mgl-map-wrapper::v-deep > .mapboxgl-map > .mapboxgl-control-container > .mapboxgl-ctrl-top-right{
            top: 25%;
        }
    }
    .autocomplete::placeholder{
        font-size: 12px;
    }
    .active-style {
        color: var(--inactive-main-navy);
    }

    /* for input google autocomplete  */
    input:focus {
        box-shadow: none;
    }
    .gmap-invalid{
        background: rgb(255, 255, 255);
        position: absolute;
        left: 20px;
        top: 70px;
        width: 310px;
        border: 0px;
        border-radius: 7px;
        justify-content: center;
        padding: 10px;
        text-align: center;
    }
    .modal-main-container::v-deep .modal-main{
        max-width: 800px;
    }
    .legend-map {
        height: 20px;
        width: 20px;
        background: #a4bbde;
        border-radius: 3px;
    }

    .star-enter-active, .star-leave-active {
        transition: all .15s ease-out;
    }
    .star-enter-active {
        transition-delay: .07s;
    }
/* star */
    .star-enter, .star-leave-to {
        opacity: 0;
    }
    .star-leave, .star-enter-to {
        opacity: 1;
    }
/* title */
    .title {
        transition: var(--transition-1);
    }
    .title-left {
        left: 0;
    }
    .title-right {
        left: 30px;
        transition: var(--transition-1);
    }
    .main-input::v-deep > .error-pop{
        bottom: -32px;
    }
</style>
