<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g id="Layer_10">
            <g>
            <path class="st18" d="M136.2,88.8c-1.7-0.8-5.5-1.9-7.9,0c-7,5.5-10.6,12-10.7,19.5c-0.1,2.6,1.7,5.8,3.9,7.1    c9.4,5.6,15.2,13.6,16.3,22.6c1.1,9-2.5,18.2-10.1,25.9L81,210.5c-5.8,5.8-13.2,9-21,9c0,0,0,0,0,0c-7.7,0-15-3.1-20.6-8.8    c-11.3-11.4-11-29.6,0.8-41.4c12.1-12.1,24.1-24.2,36-36.3c1.3-1.3,3-4.2,2.3-6.9c-2.3-9.1-2.7-17.9-1.1-28    c0.5-3.3-3.4-5.3-5.8-3.1l-2.3,2.2c-1.3,1.2-2.3,2.2-3.3,3.2l-18.4,18.4c-9.8,9.8-19.6,19.6-29.4,29.4    c-22.9,22.9-23.4,60.3-1,83.6C28.8,243.9,44.5,250,60.1,250c20.6,0,34.9-10.7,48.9-24.4c12.2-11.8,24.2-23.8,35.9-36.1    c11.2-11.6,19.9-22.4,22.7-38.8C171.8,126.2,158.2,99.6,136.2,88.8z"/>
            <path class="st18" d="M241.8,31.2C232.3,14.9,216.5,4,198.3,1.5c-18.3-2.5-36.5,4-50,16.4c-16.4,15.1-31.8,31.3-47.4,47.3    C88,78.3,80.5,94.3,81.8,113c1.5,22,12.3,38.6,32.1,49.2c1,0.5,2.5,1,4.1,1c1.4,0,2.8-0.3,3.9-1.2c6.3-5.1,9.8-11.3,10.5-18.4    c0.4-3.5-0.8-6-3.9-8.1c-4.2-2.9-8.2-5.7-10.8-9.3c-8.8-12-7.4-27,3.6-38.1c15.7-15.8,31.5-31.5,47.2-47.2c6-6,13.6-9.3,21.6-9.3    c0,0,0,0,0,0c7.6,0,14.8,3.1,20.4,8.7c5.4,5.4,8.4,12.5,8.4,20c0,8-3.3,15.7-9.3,21.7c-12.1,12-24,24.2-36,36.3    c-1.5,1.5-2.4,4-2.2,6c0.7,7.2,1.4,14.3,2.1,21.3l0.7,6.6c0.1,1.4,1.1,2.5,2.4,3c0.7,0.2,2.3,0.7,5.3-2.2l14.4-14.3    c12.5-12.4,25-24.9,37.3-37.6C251.1,82.8,254.6,53.5,241.8,31.2z"/>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'iconReference',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>