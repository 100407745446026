<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st0" d="M135.648,19.395L135.648,19.395L22.08,2.144c-5.27-0.822-10.648,0.62-14.679,4.03   C3.372,9.692,1,14.745,1,20.015v217.729c0,5.688,4.65,10.339,10.338,10.339h33.589v-54.25c0-10.029,8.06-18.089,18.088-18.089   h25.838c10.028,0,18.088,8.06,18.088,18.089v54.25h43.912v-210.8C150.838,28.385,144.421,20.852,135.648,19.395z M57.838,152.493   h-15.5c-4.278,0-7.75-3.472-7.75-7.75c0-4.278,3.472-7.75,7.75-7.75h15.5c4.278,0,7.75,3.472,7.75,7.75   C65.588,149.021,62.117,152.493,57.838,152.493z M57.838,121.493h-15.5c-4.278,0-7.75-3.472-7.75-7.75   c0-4.278,3.472-7.75,7.75-7.75h15.5c4.278,0,7.75,3.472,7.75,7.75C65.588,118.021,62.117,121.493,57.838,121.493z M57.838,90.493   h-15.5c-4.278,0-7.75-3.472-7.75-7.75c0-4.278,3.472-7.75,7.75-7.75h15.5c4.278,0,7.75,3.472,7.75,7.75   C65.588,87.021,62.117,90.493,57.838,90.493z M57.838,59.493h-15.5c-4.278,0-7.75-3.472-7.75-7.75c0-4.278,3.472-7.75,7.75-7.75   h15.5c4.278,0,7.75,3.472,7.75,7.75C65.588,56.021,62.117,59.493,57.838,59.493z M109.5,152.493H94c-4.278,0-7.75-3.472-7.75-7.75   c0-4.278,3.472-7.75,7.75-7.75h15.5c4.278,0,7.75,3.472,7.75,7.75C117.25,149.021,113.778,152.493,109.5,152.493z M109.5,121.493   H94c-4.278,0-7.75-3.472-7.75-7.75c0-4.278,3.472-7.75,7.75-7.75h15.5c4.278,0,7.75,3.472,7.75,7.75   C117.25,118.021,113.778,121.493,109.5,121.493z M109.5,90.493H94c-4.278,0-7.75-3.472-7.75-7.75c0-4.278,3.472-7.75,7.75-7.75   h15.5c4.278,0,7.75,3.472,7.75,7.75C117.25,87.021,113.778,90.493,109.5,90.493z M109.5,59.493H94c-4.278,0-7.75-3.472-7.75-7.75   c0-4.278,3.472-7.75,7.75-7.75h15.5c4.278,0,7.75,3.472,7.75,7.75C117.25,56.021,113.778,59.493,109.5,59.493z"/>
            <path class="st0" d="M234.74,112.116L234.74,112.116l-73.579-15.407v151.373h69.75c9.966,0,18.088-8.107,18.088-18.089V129.786   C249,121.245,243.172,113.976,234.74,112.116z M210.25,217.082h-15.5c-4.278,0-7.75-3.472-7.75-7.75s3.472-7.75,7.75-7.75h15.5   c4.278,0,7.75,3.472,7.75,7.75S214.528,217.082,210.25,217.082z M210.25,186.082h-15.5c-4.278,0-7.75-3.472-7.75-7.75   s3.472-7.75,7.75-7.75h15.5c4.278,0,7.75,3.472,7.75,7.75S214.528,186.082,210.25,186.082z M210.25,155.082h-15.5   c-4.278,0-7.75-3.472-7.75-7.75s3.472-7.75,7.75-7.75h15.5c4.278,0,7.75,3.472,7.75,7.75S214.528,155.082,210.25,155.082z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'iconBranch',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>

</style>
