<template>
    <icon-base :width="size + 'px'" :fill="color">
        <path class="st118" d="M126.4,10.9l26.1,80.4c1.1,3.4,4.3,5.7,7.8,5.7H245c1.4,0,2,1.8,0.9,2.7l-68.4,49.7c-2.9,2.1-4.1,5.8-3,9.2   l26.1,80.4c0.4,1.4-1.1,2.5-2.3,1.7L129.8,191c-2.9-2.1-6.8-2.1-9.7,0l-68.4,49.7c-1.2,0.8-2.7-0.3-2.3-1.7l26.1-80.4   c1.1-3.4-0.1-7.1-3-9.2L4.2,99.7C3,98.9,3.6,97,5,97h84.6c3.6,0,6.7-2.3,7.8-5.7l26.1-80.4C124,9.6,126,9.6,126.4,10.9z"/>
    </icon-base>
</template>

<script>
export default {
    name: 'iconStar',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
	.st118{fill:#EACF4B;}

</style>
