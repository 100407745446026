<template>
<icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <g>
                    <g>
                        <ellipse class="st0" cx="125" cy="241.4" rx="99.2" ry="8.1"/>
                        <g class="st1">
                            <path class="st2" d="M188.6,140l-14.4-14.4c-1.5-1.5-4-1.5-5.6,0c-0.7,0.7-1.2,1.7-1.2,2.8c0,1.1,0.4,2,1.2,2.8l7.7,7.7h-42.8       c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9h42.8l-7.7,7.7c-1.5,1.5-1.5,4,0,5.6c0.7,0.7,1.7,1.2,2.8,1.2s2.1-0.4,2.8-1.2       l14.4-14.4c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.1-0.2,0.2-0.3v-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.1-0.3,0.2-0.5       c0-0.1,0-0.1,0.1-0.2C189.9,142.3,189.5,141,188.6,140z"/>
                            <g class="st3">
                                <path class="st4-location" d="M143.9,209.9H72.4c-6.7,0-12.1-5.4-12.1-12.1V97.6c0-6.7,5.4-12.1,12.1-12.1H144        c6.7,0,12.1,5.4,12.1,12.1v22.3c0,2.3-1.9,4.1-4.1,4.1s-4.1-1.9-4.1-4.1V97.6c0-2.1-1.7-3.8-3.8-3.8H72.4        c-2.1,0-3.8,1.7-3.8,3.8v100.3c0,2.1,1.7,3.8,3.8,3.8H144c2.1,0,3.8-1.7,3.8-3.8v-31.6c0-2.3,1.9-4.1,4.1-4.1s4.1,1.9,4.1,4.1        v31.6C156,204.5,150.6,209.9,143.9,209.9z"/>
                            </g>
                            <path class="st2" d="M103.4,220.8l-34.2-11c-5.3-1.7-8.9-6.6-8.9-12.2V97c0-6.3,6.1-10.8,12.2-8.9l31,9.7l7.5,2.6       c4.2,1.4,7,5.4,7,9.8v100.3C118,218.1,110.5,223.4,103.4,220.8z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path class="st5" d="M125,0.5c-18.1,0-32.8,14.8-32.8,33c0,25.9,29.7,52.6,31,53.7c0.5,0.5,1.2,0.7,1.8,0.7s1.3-0.2,1.8-0.7      c1.3-1.1,31-27.8,31-53.7C157.8,15.3,143.1,0.5,125,0.5L125,0.5z M125,51.5c-10,0-18.2-8.2-18.2-18.2S115,15.1,125,15.1      s18.2,8.2,18.2,18.2S135,51.5,125,51.5z"/>
                    </g>
                    <g>
                        <g>
                            <path class="st4-location" d="M75.9,189.6c0-6,0-12,0-18c0-1.2-0.2-1.7-1.6-1.7c-5.3,0-9.1-2.5-11-7.5c-1.9-4.9-0.4-9.1,3.2-12.8       c16.6-16.5,33.1-33.1,49.7-49.7c5.5-5.5,12.1-5.5,17.5,0c16.7,16.6,33.3,33.3,50,50c6.6,6.6,4.1,16.9-4.6,19.5       c-1,0.3-2.2,0.5-3.3,0.4c-1.6-0.1-1.8,0.6-1.8,1.9c0,11.6,0,23.3,0,34.9c0,7.2-4.4,12.6-11.2,13.9c-0.8,0.1-1.6,0.2-2.3,0.2       c-6.4,0-12.9,0-19.3,0c-2.8,0-4.2-1.3-4.2-4.2c0-9.2,0-18.5,0-27.7c0-5-2.3-7.3-7.3-7.3c-3.4,0-6.8,0-10.2,0       c-4.2,0-6.6,2.5-6.7,6.7c0,9.2,0,18.5,0,27.7c0,3.6-1.2,4.7-4.8,4.7c-6.1,0-12.2,0-18.3,0c-8,0-13.8-5.8-13.9-13.8       C75.9,201.3,75.9,195.5,75.9,189.6z"/>
                        </g>
                        <g>
                            <path class="st4-location" d="M175.2,135.6V96.7c0-1.2-0.9-2.1-2.1-2.1h-27.4c-1.2,0-2.1,0.9-2.1,2.1v7.3L175.2,135.6z"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
</icon-base>
</template>

<script>
export default {
name: 'iconConfirmAddress',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
        default: 20
    },
        color: {
    type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{fill:#F0F4F9;}
	.st1{display:none;}
	.st2{display:inline;fill:#4979B2;}
	.st3{display:inline;}
	.st4-location{fill:#212E3D;}
	.st5{fill:#BC2C35;}
</style>
